var site = site || {};
site.features = {
    has_auto_replenishment : false,
    has_checkout_samples : true,
    show_offer_entry_field : true,
    allows_anonymous_checkout : true,
    has_samples_only_checkout : false,
    search_type : 'endeca',
    uses_cheetah_mail_for_email_signup_email : true,
    uses_cheetah_mail_for_registration_email : true,
    personalization_is_enabled : true,
    use_frontend_device_detection : true,
    analytics_tagged : true,
    has_giftcards : true,
    has_waitlist_notification : true,
    has_favorites : true,
    has_pro_site : false,
    show_legal_acceptance_checkbox : false,
    has_loyalty_program : false,
    pre_check_optin : false
};
